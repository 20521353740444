body {
  font-family: "Rubik", sans-serif !important;
  background-color: #033163;
  letter-spacing: 1px;
  overflow-x: hidden;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.navbar-brand a:hover {
  color: black;
}
